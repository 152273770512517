@font-face {
  font-family: "iconfont";
  src: url('../fonts/iconfont.eot');
  src: url('../fonts/iconfont.eot?#iefix') format('eot'),
    url('../fonts/iconfont.woff') format('woff'),
    url('../fonts/iconfont.ttf') format('truetype'),
    url('../fonts/iconfont.svg#iconfont') format('svg');
}

@mixin icon-styles {
  font-family: "iconfont";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include icon-styles;
}

@function icon-char($filename) {
  $char: "";

  @if $filename == calender {
    $char: "\E001";
  }
  @if $filename == jidousyo {
    $char: "\E002";
  }
  @if $filename == jitsuyou {
    $char: "\E003";
  }
  @if $filename == minus {
    $char: "\E004";
  }
  @if $filename == plus {
    $char: "\E005";
  }
  @if $filename == twitter {
    $char: "\E006";
  }
  @if $filename == key {
    $char: "\E007";
  }
  @if $filename == pen {
    $char: "\E008";
  }
  @if $filename == read {
    $char: "\E009";
  }
  @if $filename == tag {
    $char: "\E00A";
  }
  @if $filename == icon-04 {
    $char: "\E00B";
  }
  @if $filename == book {
    $char: "\E00C";
  }
  @if $filename == phone {
    $char: "\E00D";
  }
  @if $filename == search {
    $char: "\E00E";
  }
  @if $filename == arr {
    $char: "\E00F";
  }
  @if $filename == book-02 {
    $char: "\E010";
  }
  @if $filename == present {
    $char: "\E011";
  }

  @return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
  &:#{$insert} {
    @if $extend {
      @extend %icon;
    } @else {
      @include icon-styles;
    }
    content: icon-char($filename);
  }
}

.icon-calender {
  @include icon(calender);
}
.icon-jidousyo {
  @include icon(jidousyo);
}
.icon-jitsuyou {
  @include icon(jitsuyou);
}
.icon-minus {
  @include icon(minus);
}
.icon-plus {
  @include icon(plus);
}
.icon-twitter {
  @include icon(twitter);
}
.icon-key {
  @include icon(key);
}
.icon-pen {
  @include icon(pen);
}
.icon-read {
  @include icon(read);
}
.icon-tag {
  @include icon(tag);
}
.icon-icon-04 {
  @include icon(icon-04);
}
.icon-book {
  @include icon(book);
}
.icon-phone {
  @include icon(phone);
}
.icon-search {
  @include icon(search);
}
.icon-arr {
  @include icon(arr);
}
.icon-book-02 {
  @include icon(book-02);
}
.icon-present {
  @include icon(present);
}
